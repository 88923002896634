import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  // If no token or role, redirect to login
  if (!token || !role) {
    return <Navigate to="/login" />;
  }

  // Render the children (admin components) if the user is authenticated
  return children;
};

export default PrivateRoute;
