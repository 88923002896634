import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../css/Distrubution.css'; // Import the CSS file
import Adminpanel from './Adminpanel';
import Swal from 'sweetalert2';
import { BASE_SERVER_URL } from '../../utils/Api';
const Distrubution = () => {
  const [contacts, setContacts] = useState([]);

  // Fetch contact data from the API
  useEffect(() => {


    fetchContacts();
  }, []);
  const fetchContacts = async () => {
    try {

       // Get the token from local storage
      const token = localStorage.getItem('token');
      
      const response = await axios.get(`${BASE_SERVER_URL}/distribution`, {
        headers: {
          'token': `${token}`, // Attach the token in the Authorization header
        },
      }); // Replace with your actual API URL
      setContacts(response.data.reverse());
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Get the token from local storage
          const token = localStorage.getItem('token');

          const response = await axios.delete(`${BASE_SERVER_URL}/distribution/${id}`, {
            headers: { 'token': `${token}` }, // Attach the token in the Authorization header

          }); // Ensure this URL is correct
          console.log('Delete Response:', response.data); // Check response from API
          Swal.fire('Deleted!', 'Your distrubution has been deleted.', 'success');
          fetchContacts(); // Refresh the list after deletion
        } catch (error) {
          console.error('Error deleting distrubution:', error.response ? error.response.data : error.message);
          Swal.fire(
            'Error!',
            'Failed to delete the distrubution. Please try again.',
            'error'
          );
        }
      }
    });
  };
  return (
    <>
      <Adminpanel onToggleSidebar={handleSidebarToggle} />
      <div className={`distrubution-container ${isSidebarOpen ? "sidebar-open" : "sidebar-close"} `}>
        <h2 className="distrubution-heading">Distribution List</h2>
        <div className="distrubution-table-wrapper">
          <table className="distrubution-table">
            <thead>
              <tr className="distrubution-table-header-row">
                <th className="distrubution-table-header">S.No</th>
                <th className="distrubution-table-header">Name</th>
                <th className="distrubution-table-header">Last Name</th>
                <th className="distrubution-table-header">Email</th>
                <th className="distrubution-table-header">Phone Number</th>
                <th className="distrubution-table-header">Company</th>
                <th className="distrubution-table-header">Type</th>
                <th className="distrubution-table-header">Distribution Address</th>
                <th className="distrubution-table-header">Shipping Address</th>
                <th className="distrubution-table-header">Food Traffic</th>
                <th className="distrubution-table-header">Age</th>
                <th className="distrubution-table-header">Monthly Beverages</th>
                <th className="distrubution-table-header">Loading Dock</th>
                <th className="distrubution-table-header">Reason</th>
                <th className="distrubution-table-header">City</th>
                <th className="contactget-table-header">Actions</th>
              </tr>
            </thead>
            <tbody>
              {contacts.length > 0 ? (
                contacts.map((contact, index) => (
                  <tr key={index} className="distrubution-table-row">
                    <td className="distrubution-table-data">{index + 1}</td>
                    <td className="distrubution-table-data">{contact.name}</td>
                    <td className="distrubution-table-data">{contact.lastName}</td>
                    <td className="distrubution-table-data">{contact.email}</td>
                    <td className="distrubution-table-data">{contact.phone}</td>
                    <td className="distrubution-table-data">{contact.company}</td>
                    <td className="distrubution-table-data">{contact.businesstype}</td>
                    <td className="distrubution-table-data">{contact.distributionAddress}</td>
                    <td className="distrubution-table-data">{contact.shippingAddress}</td>
                    <td className="distrubution-table-data">{contact.foodTraffic}</td>
                    <td className="distrubution-table-data">{contact.ageRange}</td>
                    <td className="distrubution-table-data">{contact.monthlyBeverages}</td>
                    <td className="distrubution-table-data">{contact.elevatedDock ? "Yes" : "No"}</td>
                    <td className="distrubution-table-data">{contact.distributeReason}</td>
                    <td className="distrubution-table-data">{contact.agreeToShare}</td>
                    <td className="contactget-table-data">
                      <button
                        className="contactget-delete-button"
                        onClick={() => handleDelete(contact._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="contactget-no-data-row">
                  <td colSpan="16" className="contactget-no-data">No Distribution available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Distrubution;
