// import React, { useState,useEffect,useRef } from 'react';
// import axios from 'axios';
// import Adminpanel from './Adminpanel';
// import '../../css/Uploadcan.css';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import Swal from 'sweetalert2';
// import { useNavigate } from "react-router-dom";
// import { BASE_SERVER_URL } from '../../utils/Api';
// import { Spinner } from 'react-bootstrap';
// import { ToastContainer, toast } from 'react-toastify'; // Import Toastify
// import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

// const Uploadcan = () => {
//   const navigate = useNavigate();
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);
//   const [frontImg, setFrontImg] = useState(null);
//   const [backImg, setBackImg] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [textData, setTextData] = useState('');
//   const [textLink, setTextLink] = useState('');

//   useEffect(() => {
//     AOS.init({
//       duration: 1000, // Set duration of animation
//       once: true,     // Only animate once on load
//     });
  
//     // Reinitialize AOS on every component update
//     AOS.refresh();
  
//   }, []);


//   // Create refs for file inputs
//   const frontImgRef = useRef(null);
//   const backImgRef = useRef(null);

//   const handleSidebarToggle = (isOpen) => {
//     setIsSidebarOpen(isOpen);
//   };

//   const handleImageUpload = async (e) => {
//     e.preventDefault();
  
//     if (!frontImg || !backImg || !textData) {
//       Swal.fire({
//         icon: 'warning',
//         title: 'Incomplete Data',
//         text: 'Please select both images and enter the text data.',
//       });
//       return;
//     }
  
//     const formData = new FormData();
//     formData.append('frontImg', frontImg);
//     formData.append('backImg', backImg);
//     formData.append('textData', textData);
//     formData.append('textLink', textLink);
//     setLoading(true);
  
//     try {
//       // Get the token from local storage
//       const token = localStorage.getItem('token');
  
//       const response = await axios.post(`${BASE_SERVER_URL}/upload-images`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           'token': `${token}` // Attach the token in the Authorization header
//         },
//       });
  
//       // Show Toastify notification for successful upload
//       toast.success(response.data.message || 'Images uploaded successfully!');
  
//       Swal.fire({
//         icon: 'success',
//         title: 'Success!',
//         text: response.data.message || 'Images uploaded successfully!',
//       });
  
//       // Reset the input fields
//       setFrontImg(null);
//       setBackImg(null);
//       setTextData('');
//       setTextLink('');
  
//       // Clear file input values
//       frontImgRef.current.value = null;
//       backImgRef.current.value = null;
  
//       // Navigate to the spotlight-wall page after 2 seconds
//       setTimeout(() => {
//         navigate("/admin-spotlight-wall");
//       }, 2000);
  
//     } catch (error) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Upload Failed',
//         text: 'Failed to upload images. Please try again.',
//       });
//     } finally {
//       setLoading(false); // Reset loading state
//     }
//   };
  


//   return (
//     <>
//       <Adminpanel onToggleSidebar={handleSidebarToggle} />
//       <div className={`contactget-container ${isSidebarOpen ? "open" : "close"}`}>
//         <h2 className="uploadcan-heading">Upload Cans Image</h2>

//         <form className="uploadcan-form" onSubmit={handleImageUpload} data-aos="zoom-out-down">
//           <div className="uploadcan-form-group" data-aos="zoom-out-down">
//             <label htmlFor="frontImg" className="uploadcan-label">Front Image:</label>
//             <input
//               type="file"
//               id="frontImg"
//               className="uploadcan-input"
//               accept="image/*"
//               ref={frontImgRef}
//               onChange={(e) => setFrontImg(e.target.files[0])}
//             />

// {frontImg && (
//     <div className="uploadcan-img-container">
//       <img
//         src={URL.createObjectURL(frontImg)}
//         alt="Front Preview"
//         className="uploadcan-img"
//       />
//     </div>
//   )}
//           </div>
//           <div className="uploadcan-form-group" data-aos="zoom-out-down">
//             <label htmlFor="backImg" className="uploadcan-label">Back Image:</label>
//             <input
//               type="file"
//               id="backImg"
//               className="uploadcan-input"
//               accept="image/*"
//               ref={backImgRef}
//               onChange={(e) => setBackImg(e.target.files[0])}
//             />
// {backImg && (
//     <div className="uploadcan-img-container">
//       <img
//         src={URL.createObjectURL(backImg)}
//         alt="Back Preview"
//         className="uploadcan-img"
//       />
//     </div>
//   )}
//           </div>
//           <div className="uploadcan-form-group" data-aos="zoom-out-down">
//             <label htmlFor="textData" className="uploadcan-label">Text Data:</label>
//             <input
//               type="text"
//               id="textData"
//               className="uploadcan-input"
//               value={textData}
//               onChange={(e) => setTextData(e.target.value)}
//             />
//           </div>
//           <div className="uploadcan-form-group" data-aos="zoom-out-down">
//             <label htmlFor="textLink" className="uploadcan-label">Text Link:</label>
//             <input
//               type="text"
//               id="textLink"
//               className="uploadcan-input"
//               value={textLink}
//               onChange={(e) => setTextLink(e.target.value)}
//             />
//           </div>
//           <button type="submit" className="uploadcan-submit"
//             disabled={loading}>
//             {loading ? (
//               <>
//                 <Spinner animation="border" size="sm" />
//                 <span className="ms-2">Uploading...</span>
//               </>
//             ) : (
//               "Upload"
//             )}
//           </button>
//         </form>

//         {/* Toastify container to display toasts */}
//         <ToastContainer />
//       </div>
//     </>
//   );
// };

// export default Uploadcan;



import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Adminpanel from './Adminpanel';
import '../../css/Uploadcan.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import { BASE_SERVER_URL } from '../../utils/Api';
import { Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify'; // Import Toastify
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

const Uploadcan = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [frontImg, setFrontImg] = useState(null);
  const [backImg, setBackImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [textData, setTextData] = useState('');
  const [textLink, setTextLink] = useState('');

  useEffect(() => {
    AOS.init({
      duration: 1000, // Set duration of animation
      once: true,     // Only animate once on load
    });
  
    // Reinitialize AOS on every component update
    AOS.refresh();
  
  }, []);

  // Create refs for file inputs
  const frontImgRef = useRef(null);
  const backImgRef = useRef(null);

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  // Helper function to check image dimensions and aspect ratio
  const checkImageDimensions = (image) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        // Allow a slight tolerance for aspect ratio (0.50 to 0.54)
        if (aspectRatio >= 0.50 && aspectRatio <= 0.54) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.src = URL.createObjectURL(image);
    });
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
  
    if (!frontImg || !backImg || !textData) {
      Swal.fire({
        icon: 'warning',
        title: 'Incomplete Data',
        text: 'Please select both images and enter the text data.',
      });
      return;
    }
    
    // Check the aspect ratio of both images
    const isFrontImgValid = await checkImageDimensions(frontImg);
    const isBackImgValid = await checkImageDimensions(backImg);
  
    if (!isFrontImgValid || !isBackImgValid) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Image Dimensions',
        text: 'Images must have an aspect ratio of approximately 0.52. Please upload images with correct dimensions.',
      });
      return;
    }
  
    const formData = new FormData();
    formData.append('frontImg', frontImg);
    formData.append('backImg', backImg);
    formData.append('textData', textData);
    formData.append('textLink', textLink);
    setLoading(true);
  
    try {
      // Get the token from local storage
      const token = localStorage.getItem('token');
  
      const response = await axios.post(`${BASE_SERVER_URL}/upload-images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': `${token}` // Attach the token in the Authorization header
        },
      });
  
      // Show Toastify notification for successful upload
      toast.success(response.data.message || 'Images uploaded successfully!');
  
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: response.data.message || 'Images uploaded successfully!',
      });
  
      // Reset the input fields
      setFrontImg(null);
      setBackImg(null);
      setTextData('');
      setTextLink('');
  
      // Clear file input values
      frontImgRef.current.value = null;
      backImgRef.current.value = null;
  
      // Navigate to the spotlight-wall page after 2 seconds
      setTimeout(() => {
        navigate("/admin-spotlight-wall");
      }, 2000);
  
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Upload Failed',
        text: 'Failed to upload images. Please try again.',
      });
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <>
      <Adminpanel onToggleSidebar={handleSidebarToggle} />
      <div className={`contactget-container ${isSidebarOpen ? "open" : "close"}`}>
        <h2 className="uploadcan-heading">Upload Cans Image</h2>
        <p className="uploadcan-instructions">
          Please upload images with an aspect ratio of 0.52, for example: 500x960 px or 1000x1920 px.
        </p>

        <form className="uploadcan-form" onSubmit={handleImageUpload} data-aos="zoom-out-down">
          <div className="uploadcan-form-group" data-aos="zoom-out-down">
            <label htmlFor="frontImg" className="uploadcan-label">Front Image:</label>
            <input
              type="file"
              id="frontImg"
              className="uploadcan-input"
              accept="image/*"
              ref={frontImgRef}
              onChange={(e) => setFrontImg(e.target.files[0])}
            />

            {frontImg && (
              <div className="uploadcan-img-container">
                <img
                  src={URL.createObjectURL(frontImg)}
                  alt="Front Preview"
                  className="uploadcan-img"
                />
              </div>
            )}
          </div>

          <div className="uploadcan-form-group" data-aos="zoom-out-down">
            <label htmlFor="backImg" className="uploadcan-label">Back Image:</label>
            <input
              type="file"
              id="backImg"
              className="uploadcan-input"
              accept="image/*"
              ref={backImgRef}
              onChange={(e) => setBackImg(e.target.files[0])}
            />
            
            {backImg && (
              <div className="uploadcan-img-container">
                <img
                  src={URL.createObjectURL(backImg)}
                  alt="Back Preview"
                  className="uploadcan-img"
                />
              </div>
            )}
          </div>

          <div className="uploadcan-form-group" data-aos="zoom-out-down">
            <label htmlFor="textData" className="uploadcan-label">Text Data:</label>
            <input
              type="text"
              id="textData"
              className="uploadcan-input"
              value={textData}
              onChange={(e) => setTextData(e.target.value)}
            />
          </div>

          <div className="uploadcan-form-group" data-aos="zoom-out-down">
            <label htmlFor="textLink" className="uploadcan-label">Text Link:</label>
            <input
              type="text"
              id="textLink"
              className="uploadcan-input"
              value={textLink}
              onChange={(e) => setTextLink(e.target.value)}
            />
          </div>

          <button type="submit" className="uploadcan-submit" disabled={loading}>
            {loading ? (
              <>
                <Spinner animation="border" size="sm" />
                <span className="ms-2">Uploading...</span>
              </>
            ) : (
              "Upload"
            )}
          </button>
        </form>

        {/* Toastify container to display toasts */}
        <ToastContainer />
      </div>
    </>
  );
};

export default Uploadcan;

