import React from 'react';
import 'aos/dist/aos.css'; // Ensure you import AOS styles
import AOS from 'aos'; // Import AOS for animations
import { useEffect } from 'react';
import img from '../images/hand3.png'
// import '../css/style.css'
import '../css/Banner.css'
import { Link } from 'react-router-dom';
const Banner = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with animation duration
  }, []);

  return (
    <>
    <div
      className="container-fluid"
      id='banner'
      style={{
        backgroundSize: '100%',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6" style={{ position: 'relative' }}>
            <img
              src={img}
              alt="Hand"
              data-aos="zoom-in-down"
              className="img-fluid"
              style={{position:"relative", bottom: 0, right: 0, marginTop:"50px" }}
            />
          </div>
          <div
            className="col-md-6 d-flex flex-column justify-content-center align-items-center"
            style={{ height: '460px' }}
          >
            <h1
              className="text-center"
              style={{
                color: '#22B1DF',
                marginBottom: '80px',
                WebkitTextStroke: '1px white',
                fontWeight: 'bold'
              }}
              data-aos="zoom-in-down"
            >
              Your Brand In Everyone's Hand!
            </h1>
            <div className="button-container mb-5" data-aos="zoom-in-down">
              <Link to='/Advertise' className="btn">
                ADVERTISE WITH US
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Banner;
