import React from 'react';
import { BrowserRouter, Routes, Route,Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Spotlightwall from './pages/Spotlightwall';
import AdminSpotlightwall from './pages/Dashboard/AdminSpotlight';
import ResetPass from './pages/Dashboard/ResetPass';
import ContactUs from './pages/ContactUs ';
import FAQSection from './pages/FAQSection';
import Advestisement from './pages/Advestisement';
import Distribute from './pages/Distribute';
import Termscondition from './pages/Terms&condition';
import Login from './pages/Login';
import Contactget from './pages/Dashboard/Contactget';
import Subscription from './pages/Dashboard/Subscription';
import Distrubution from './pages/Dashboard/Distrubution';
import Quotes from './pages/Dashboard/Quotes';
import Dashboard from './pages/Dashboard/Dashboard';
import PrivateRoute from './component/PrivateRoute'; // Import your PrivateRoute component
import Uploadcan from './pages/Dashboard/Uploadcan';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Redirect from /hall-of-fame to /spotlight-wall */}
          <Route
            path="/hall-of-fame"
            element={<Navigate to="/spotlight-wall" replace />}
          />
          <Route path="/spotlight-wall" element={<Spotlightwall />} />
          <Route path="/Contactus" element={<ContactUs />} />
          <Route path="/FAQSection" element={<FAQSection />} />
          <Route path="/Advertise" element={<Advestisement />} />
          <Route path="/Distribute" element={<Distribute />} />
          <Route path="/Termscondition" element={<Termscondition />} />
          <Route path="/login" element={<Login />} />

          {/* Private Routes */}
          <Route
            path="/Dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/Contactget"
            element={
              <PrivateRoute>
                <Contactget />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription"
            element={
              <PrivateRoute>
                <Subscription />
              </PrivateRoute>
            }
          />
          <Route
            path="/Distrubution"
            element={
              <PrivateRoute>
                <Distrubution />
              </PrivateRoute>
            }
          />
          <Route
            path="/quotes"
            element={
              <PrivateRoute>
                <Quotes />
              </PrivateRoute>
            }
          />
          {/* Protect admin routes */}
          
          <Route
          path="/admin-spotlight-wall"
          element={
            <PrivateRoute>
              <AdminSpotlightwall />
            </PrivateRoute>
          }
        />
          <Route
          path="/reset-pass"
          element={
            <PrivateRoute>
              <ResetPass/>
            </PrivateRoute>
          }
        />
          <Route
            path="/Uploadcan"
            element={
              <PrivateRoute>
                <Uploadcan />
              </PrivateRoute>
            }
          />

          {/* Fallback Route for undefined paths */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
