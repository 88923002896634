import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import '../css/Footer.css'; // Import custom CSS file for the footer
import img from '../images/social/facebook.png'
import img1 from '../images/social/x.png'
import img2 from '../images/social/linkedin.png'
import img3 from '../images/social/instagram.png'
import img4 from '../images/social/youtube.png'
import img5 from '../images/social/tiktok.png'
// import img6 from '../images/social/facebook.png';
import { Link } from 'react-router-dom';
import '../css/Banner.css'
const Footer = () => {
  return (
    <footer className="footer">
      <div className="waves">
        <div className="wave" id="wave1"></div>
        <div className="wave" id="wave2"></div>
        <div className="wave" id="wave3"></div>
        <div className="wave" id="wave4"></div>
      </div>

      <Container style={{marginLeft:"-7px"}}>
        <Row className='footerring'>
          <Col>
            <h4 style={{ fontWeight: 600 }}>Because water should be free</h4>
            <p>
              Help us spread the word and share our movement<br />
              with your friends and family
            </p>
    <Row className='riding close-cols g-0'>
  <Col className='footer-width col-md-4'>
    <Link to="/Contactus" className='btn to-adjust_btn' onClick={() => window.scrollTo(0, 0)}>
      CONTACT US
    </Link>
  </Col>  
  <Col className='footer-width col-md-8'>
    <p className='footer-ptag'>
      <Link to="/Advertise" className='fix-the_lines' onClick={() => window.scrollTo(0, 0)} style={{ textDecoration: 'none', color: '#fff' }}>
        Order FreeHydration Boxes
      </Link>
    </p>
    <p className='footer-ptag'>
      <Link to="/Advertise" className='fix-the_lines' onClick={() => window.scrollTo(0, 0)} style={{ textDecoration: 'none', color: '#fff' }}>
        Order FreeHydration Cans
      </Link>
    </p>
  </Col>
  </Row>

          </Col>
          <Col className="d-flex align-items-center justify-content-center flex-column fix_thePostion">
            <div className="text-center p-1">
              <Link to="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <img src={img} alt="Facebook" style={{ height: '30px' }} />
              </Link>
              <Link to="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <img src={img1} alt="Twitter" style={{ height: '30px', marginLeft: '5px' }} />
              </Link>
              <Link to="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <img src={img2} alt="LinkedIn" style={{ height: '30px', marginLeft: '5px' }} />
              </Link>
              <Link to="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <img src={img3} alt="Instagram" style={{ height: '30px', marginLeft: '5px' }} />
              </Link>
              <Link to="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                <img src={img4} alt="YouTube" style={{ height: '30px', marginLeft: '5px' }} />
              </Link>
              <Link to="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
                <img src={img5} alt="TikTok" style={{ height: '30px', marginLeft: '5px' }} />
              </Link>
            </div>

          <div className='div-for_text_and_btn'>
          <p className="text-center">
          <label htmlFor="email" className="text-light text-center" >Subscribe to Our Newsletter</label>
          </p>
              
        <div className="conateae">
        <span style={{marginRight:"10px"}}>
            <input
            type="email"
            name="email"
            placeholder="Enter your email"
            required
            style={{ padding: '10px', borderRadius: '30px', marginLeft:"5px" }}
          />  
          </span>
          <span>
            <input type="submit" value="SUBSCRIBE" className="btn btn-subscribe" />
          </span>
        </div>
            </div>
          </Col>
        </Row>
      </Container>
      <p className='prox' >&copy;FreeHydration &nbsp; | All Rights Reserved  &nbsp; | &nbsp;
              <Link to="/Termscondition"  onClick={() => window.scrollTo(0, 0)} style={{ textDecoration: 'none', color: '#fff' }}>
                Terms & Conditions
              </Link>
              </p>
    </footer>
  );
};

export default Footer;
