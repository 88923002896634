import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS for animations
import img from '../images/cost_no_background_animated.gif'
import img1 from '../images/natural-product.gif'
import img2 from '../images/effervescent.gif'
import img3 from '../images/medical-insurance-transparent.gif'
import img4 from '../images/boy_drinking_water.jpg'
import img5 from '../images/crushed_bottle.jpg'
import img6 from '../images/step01.webp';
import designimg from '../images/designing.webp';
import production from '../images/Productionimage2.jpeg'
import { Link } from 'react-router-dom';
import '../css/HydrationSection.css'
const HydrationSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with animation duration
  }, []);

  return (
    <>
      <div className="container-fluid p-3 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="container">
                <div className="row text-center">
                  <div className="col-md-6 bg-light p-5" data-aos="fade-up">
                    <img
                      src={img}
                      alt="Save Water"
                      className="img-fluid"
                      style={{ maxWidth: "100px" }}
                    />
                    <h3 className="m-3">Save Money</h3>
                    <p>
                      Bottled water costs 2,000 times the price of tap water
                    </p>
                  </div>
                  <div className="col-md-6 p-5" data-aos="fade-up">
                    <img
                      src={img1}
                      alt="Eco Friendly"
                      className="img-fluid"
                      style={{ maxWidth: "100px" }}
                    />
                    <h3 className="m-3">Eco Friendly</h3>
                    <p>Our goal is to be net positive by 2030</p>
                  </div>
                  <div className="col-md-6 p-5" data-aos="fade-up">
                    <img
                      src={img2}
                      alt="Premium Water"
                      className="img-fluid"
                      style={{ maxWidth: "100px" }}
                    />
                    <h3 className="m-3">Premium Water</h3>
                    <p>
                      FreeHydration is natural spring water and all of our
                      containers are BPA free
                    </p>
                  </div>
                  <div className="col-md-6 p-5 bg-light" data-aos="fade-up">
                    <img
                      src={img3}
                      alt="Save Lives"
                      className="img-fluid"
                      style={{ maxWidth: "100px" }}
                    />
                    <h3 className="m-3">Save Lives</h3>
                    <p>
                      Ten cents from each beverage is donated to charity to
                      build water wells for people in need
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
  className="three-steps-container p-3"
  data-aos="fade-up"
  data-aos-duration="1200"
>
  <h1
    data-aos="fade-right"
    data-aos-duration="1000"
    className="text-center mb-5 main-txtclr-change"
  >
    Three Steps to Reach Your Customer!!!
  </h1>

  <div className="row justify-content-center">
    {/* Step 1 */}
    <div className="col-md-3 mb-5">
      <div className="flip-card" data-aos="fade-up" data-aos-delay="200">
        <div className="flip-card-inner">
          <div className="flip-card-front text-center p-3">
            <img
              src={img6}
              alt="Optimize the Market"
              className="img-fluid flip-image"
            />
            <h4 className="mt-3 step-title">Market Optimization</h4>
          </div>
          <div className="flip-card-back p-4">
            <h4 className="step-title">Market Optimization</h4>
            <p>
              When a brand collaborates with us, we begin by identifying the
              optimal locations for distributing the cans in public or at
              events.
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* Step 2 */}
    <div className="col-md-3 mb-5">
      <div className="flip-card" data-aos="fade-up" data-aos-delay="400">
        <div className="flip-card-inner">
          <div className="flip-card-front text-center p-3">
            <img
              src={designimg}
              alt="Designing"
              className="img-fluid flip-image"
            />
            <h4 className="mt-3 step-title">Designing</h4>
          </div>
          <div className="flip-card-back p-4">
            <h4 className="step-title">Designing</h4>
            <p>
              Next, we proceed to the design phase. Our skilled graphic design
              team can create eye-catching advertisements for the cans. If you
              already have a design for your brand, we can certainly incorporate
              that.
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* Step 3 */}
    <div className="col-md-3 mb-5">
      <div className="flip-card" data-aos="fade-up" data-aos-delay="600">
        <div className="flip-card-inner">
          <div className="flip-card-front text-center p-3">
            <img
              src={production}
              alt="Production"
              className="img-fluid flip-image"
            />
            <h4 className="mt-3 step-title">Production</h4>
          </div>
          <div className="flip-card-back p-4">
            <h4 className="step-title">Production</h4>
            <p>
              Following the design phase, we move on to the manufacturing
              process, where the cans are produced and packaged. Approximately 2
              to 3 weeks later, the final product will be ready for distribution
              to the public.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


      <div className="container-fluid p-3 mt-4">
        <div style={{ width: "100%" }} className="shadow-sm">
          .
        </div>
      </div>
      <div className="container-fluid p-3 mt-4">
        <div className="container p-0">
          <div className="row p-5">
            <div className="col-md-6 d-flex align-items-center justify-content-center mb-5">
              <div>
                <h3 style={{ color: "#22B1DF" }} data-aos="fade-up">
                  We are partnered with WellAware
                </h3>
                <div
                  className="mt-3 mb-3 border-bottom"
                  style={{ width: "50%" }}
                ></div>
                <p
                  style={{
                    color: "#22B1DF",
                    textAlign: "justify",
                    fontWeight: 300,
                    wordSpacing: "3px",
                    letterSpacing: "1px",
                    lineHeight: "30px",
                  }}
                  data-aos="fade-up"
                >
                  We donate ten cents per beverage to WellAware, an Austin-based
                  non-profit that builds water wells in East Africa. Every 150
                  FreeHydration donates enough money to provide one person with
                  a safe water source for the rest of their life.
                </p>
                <div className="button-container" data-aos="fade-up">
                  <Link
                    to={"http://www.google.com"}
                    target="_blank"
                    rel="noreferrer"
                    className="btn"
                  >
                    LEARN MORE
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={img4}
                data-aos="zoom-in-down"
                alt="Boy Drinking Water"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-3 mt-4">
        <div className="bg-light" style={{ width: "100%" }}>
          <div className="container">
            <div className="row p-4 mt-5">
              <div className="col-md-6">
                <img
                  src={img5}
                  alt="Crushed Bottle"
                  className="img-fluid"
                  data-aos="fade-up"
                />
              </div>
              <div className="col-md-6 p-5 d-flex align-items-center justify-content-center">
                <div>
                  <h3 style={{ color: "#22B1DF" }} data-aos="fade-up">
                    We never use plastic bottles
                  </h3>
                  <div
                    className="mt-3 mb-3 border-bottom"
                    style={{ width: "50%" }}
                  ></div>
                  <p
                    style={{
                      color: "#22B1DF",
                      fontWeight: 300,
                      wordSpacing: "3px",
                      letterSpacing: "1px",
                      lineHeight: "30px",
                    }}
                    data-aos="fade-up"
                  >
                    FreeHydration comes in aluminum bottles and paper cartons of
                    natural spring water because we didn't want to make water
                    free and do it in a plastic bottle.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HydrationSection;