import React, { useEffect, useState } from 'react';
import { Tab, Nav, Accordion } from 'react-bootstrap';
import 'aos/dist/aos.css'; // Ensure you have AOS styles imported
import Footer from '../component/Footer';
import Header from '../component/Header';
import AOS from 'aos';
const FAQSection = () => {
  const [activeKey, setActiveKey] = useState('home');
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with animation duration
  }, []);
  return (
    <>
    <Header/>
    <div className="container mb-5">
      <div className="row p-5 text-center">
        <h1 style={{ color: '#22B1DF' }} data-aos="zoom-out-down">Frequently Asked Questions</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
            <Nav variant="tabs" className="mb-3">
              <Nav.Item>
                <Nav.Link eventKey="home">General</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="profile">Advertising</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="other">Other</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content style={{marginBottom: "66px"}} >
              <Tab.Pane eventKey="home">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>How is FreeHydration water free?</Accordion.Header>
                    <Accordion.Body>
                      Our water is paid for by the ads that are on the main body of the packaging.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>What are the packaging materials?</Accordion.Header>
                    <Accordion.Body>
                      <p><strong>Aluminium Cans</strong></p>
                      <p>Aluminium is the most recyclable packaging and is the world's most recycled material which makes our cans much more environmentally friendly than single-use plastics. Also not to forget, the cans are more classy and much better looking than your average plastic bottle.</p>
                      <p><strong>Paper-Based Cartons</strong></p>
                      <p>Our cartons are made entirely from renewable materials. The main paper body contains wood fibres from responsibly harvested trees, while the shoulder and the cap are made from sugarcane. Both materials utilised are 100% renewable and can grow back which lowers our carbon footprint.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>How long does it take to place an order?</Accordion.Header>
                    <Accordion.Body>
                      <p><strong>Aluminium Cans</strong></p>
                      <p>Approximately 2-3 weeks from artwork approval. However, if you have a tight deadline and cannot wait 2-3 weeks we may still be able to provide you with the cans at a slightly higher cost than usual.</p>
                      <p><strong>Paper-Based Cartons</strong></p>
                      <p>Approximately 6 weeks from artwork approval.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>How can I get involved with the growth of FreeHydration?</Accordion.Header>
                    <Accordion.Body>
                      If you would like to become part of the FreeHydration family and grow with us, please help us to spread the word by sharing us on social media with your friends and family, and sign up to our newsletter where we will be doing giveaways, keeping you motivated and keeping you updated with all you need to know about FreeHydration.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Tab.Pane>
              <Tab.Pane eventKey="profile">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>How can I advertise?</Accordion.Header>
                    <Accordion.Body>
                      To start the process of advertising with FreeHydration, please visit our advertise page and fill out our enquiry form with all the required information. We aim to contact you back within 12 hours.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>How does advertising with FreeHydration differ from more traditional marketing methods?</Accordion.Header>
                    <Accordion.Body>
                      With traditional marketing practices whether it be YouTube ads, TV ads, etc., they usually create an inconvenience for the potential consumer which can be demonstrated by the majority of people dreading and wanting to skip the ads or not taking any notice of them. Whereas with FreeHydration and our one-of-a-kind advertising technique, the consumer is actively approaching us, wanting our water, therefore wanting your advertisement which is on the packaging. As they consume our water, they also consume your ad.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Where can I advertise with FreeHydration?</Accordion.Header>
                    <Accordion.Body>
                      With FreeHydration, you can advertise your company anywhere on the island of Ireland whether it be at a stadium filled Croke Park in Dublin, a music festival in Galway, or a busy shopping street in Belfast. We constantly aim to expand our operations into new locations, with FreeHydration being available in a very exciting region later in 2024 or very early 2025. Sign up to our newsletter to be kept updated on our new locations dropping soon!
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Tab.Pane>
              <Tab.Pane eventKey="other">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>How can I get FreeHydration to distribute water at my event?</Accordion.Header>
                    <Accordion.Body>
                      If you would like us to distribute water at your event, please reach out to us through our enquiry form on our website, email or DM us on Instagram.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>I still have more questions to be answered.</Accordion.Header>
                    <Accordion.Body>
                      If you still have more questions about FreeHydration, please fill out our contact form and we will reply to you as soon as possible.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default FAQSection;
