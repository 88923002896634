import React from 'react';
import 'aos/dist/aos.css'; // Ensure AOS styles are imported
import '../css/YourComponent.css'; // Import your custom CSS if needed
import img from '../images/ad_board.png'
const YourComponent3 = () => {
  return (
    <div className="container-fluid p-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <h1
              style={{ color: '#22B1DF', textTransform: 'uppercase' }}
              className="text-center"
              data-aos="zoom-in-down"
            >
              It's a mini<br />
              billboard<br />
              in your hands
            </h1>
          </div>
          <div className="col-md-6">
            <img
              src={img}
              className="img-fluid"
              alt="Ad Board"
              data-aos="zoom-in-up"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourComponent3;
