import React, { useState, useEffect } from 'react';
import '../../css/Sidebar.css'; // Ensure you have your CSS file
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { IoMdContacts } from "react-icons/io";
import Swal from 'sweetalert2'; // Import SweetAlert

const Adminpanel = ({ onToggleSidebar }) => {
  const [isExpanded, setIsExpanded] = useState(true); // State to track the sidebar state
  const [isLoading, setIsLoading] = useState(false); // State for loading
  const [showResetPassword, setShowResetPassword] = useState(false); // State for showing reset password option
  const navigate = useNavigate(); // Hook for programmatic navigation

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded); // Toggle the state
  };

  const handleLogout = () => {
    // Show a confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to log out?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log out!',
      cancelButtonText: 'No, keep me logged in'
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true); // Set loading state to true
        // Clear authentication tokens or perform any logout logic here
        localStorage.removeItem('token'); // Example: remove token from local storage
        localStorage.removeItem('role'); // Example: remove token from local storage

        setTimeout(() => {
          setIsLoading(false); // Set loading state to false after timeout
          navigate("/login");
        }, 1000);
      }
    });
  };

  useEffect(() => {
    onToggleSidebar(isExpanded);
  }, [isExpanded, onToggleSidebar]);

  return (
    <div
      className="sidebar"
      style={{ marginLeft: isExpanded ? "0px" : "-180px" }}
    >
      <ul className="sidebar-nav">
        <li>
          <a id="navbar-toggle" onClick={toggleSidebar} href="#" role="button">
            {isExpanded ? "Open" : "Close"}{" "}
            <i className="fa fa-bars menu-icon fa-2x" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <Link to="/Dashboard">
            Home{" "}
            <i
              className="fa fa-home menu-icon fa-2x color-black"
              aria-hidden="true"
            ></i>
          </Link>
        </li>
        <li>
          <Link to="/subscription">
            Subscription{" "}
            <i
              className="fa fa-download menu-icon fa-2x"
              aria-hidden="true"
            ></i>
          </Link>
        </li>
        <li>
          <Link to="/quotes">
            Quotes{" "}
            <i
              className="fa fa-download menu-icon fa-2x"
              aria-hidden="true"
            ></i>
          </Link>
        </li>
        <li>
          <Link to="/Distrubution">
            Distrubution{" "}
            <i
              className="fa fa-download menu-icon fa-2x"
              aria-hidden="true"
            ></i>
          </Link>
        </li>
        <li>
          <Link to="/Contactget">
            Contact{" "}
            <i className="menu-icon fa-2x" aria-hidden="true">
              <IoMdContacts />
            </i>
          </Link>
        </li>
        <li>
          <Link to="/admin-spotlight-wall">
            All Cans{" "}
            <i
              className="fa fa-download menu-icon fa-2x"
              aria-hidden="true"
            ></i>
          </Link>
        </li>
        <li>
          <div
            className="settings-button"
            onMouseEnter={() => setShowResetPassword(true)} // Show reset password option on hover
            onMouseLeave={() => setShowResetPassword(false)} // Hide reset password option on mouse leave
          >
            <Link to="">
              Setting{" "}
              <i className="fa fa-cog menu-icon fa-2x" aria-hidden="true"></i>
            </Link>
            {showResetPassword && (
              <div className="reset-password-option">
                <Link to="/reset-pass">
                  Reset Password{" "}
                  <i
                    className="fa fa-key menu-icon fa-2x"
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            )}
          </div>
        </li>
        <li>
          <a onClick={handleLogout} href="#" role="button">
            Log out{" "}
            <i
              className="fa fa-sign-out menu-icon fa-2x"
              aria-hidden="true"
            ></i>
          </a>
        </li>
      </ul>

      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>{" "}
          {/* Replace with your actual loader component or text */}
          Logging out...
        </div>
      )}
    </div>
  );
};

export default Adminpanel;
