import React, { useState, useEffect } from 'react';
import '../css/Login.css'; // Import the CSS file
import Header from '../component/Header';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../component/Footer';
import { BASE_SERVER_URL } from '../utils/Api';
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons from FontAwesome

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showhidePass, setShowHidePass] = useState(false); // State to toggle old password visibility
  const navigate = useNavigate();

  // Function to toggle visibility of show password
  const toggleShowPassVisibility = () => {
    setShowHidePass((prevState) => !prevState);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Set duration of animation
      once: true, // Only animate once on load
    });

    // Reinitialize AOS on every component update
    AOS.refresh();

    // Check token validity on component mount
    checkTokenValidity();

    const interval = setInterval(checkTokenValidity, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${BASE_SERVER_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        // Save the token to localStorage with expiry time
        const expiryTime = new Date().getTime() + 2 * 60 * 1000; // 30 minutes from now
        localStorage.setItem("token", data.token);
        localStorage.setItem("role", data.user.role);
        localStorage.setItem("expiry", expiryTime);

        toast.success(data.message);
        setTimeout(() => {
          navigate("/Dashboard");
        }, 1000);
      } else {
        toast.error(data.message || "Login failed");
        setLoading(false);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  // Function to check token validity
  const checkTokenValidity = () => {
    const token = localStorage.getItem("token");
    const expiryTime = localStorage.getItem("expiry");
    const currentTime = new Date().getTime();

    if (token && expiryTime && currentTime > expiryTime) {
      // Token has expired
      logoutUser();
    }
  };

  // Function to log out the user
  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("expiry");
    toast.error("Session has expired. Please log in again.");
    navigate("/login"); // Redirect to login page
  };

  return (
    <>
      <Header />
      <div className="login-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2 className="login-title">Login</h2>

          <div className="input-group" data-aos="zoom-out-down">
            <label htmlFor="email" className="input-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="input-field"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="username"
            />
          </div>

          <div className="input-group" data-aos="zoom-out-down">
            <label htmlFor="password" className="input-label">
              Password
            </label>
            <div className="password-container">
              <input
                type={showhidePass ? "text" : "password"}
                id="password"
                className="input-field"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="current-password"
              />
              <span className="eye-icon" onClick={toggleShowPassVisibility}>
                {showhidePass ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          <button
            type="submit"
            className="login-button"
            data-aos="zoom-out-down"
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner animation="border" size="sm" />
                <span className="ms-2">Login...</span>
              </>
            ) : (
              "Login"
            )}
          </button>
        </form>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
};

export default Login;
