import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import '../../css/Contact.css';
import Adminpanel from './Adminpanel';
import { BASE_SERVER_URL } from '../../utils/Api';

const Subscription = () => {
  const [contacts, setContacts] = useState([]);
  

  // Fetch contact data from the API
  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      // Get the token from local storage
      const token = localStorage.getItem('token');
  
      const response = await axios.get(`${BASE_SERVER_URL}/subscription`, {
        headers: {
          'token': `${token}`, // Attach the token in the Authorization header
        },
      });
  
      // Assuming the response contains the contacts in an array
      setContacts(response.data.reverse());
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };
  

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  // Function to handle delete with SweetAlert confirmation
// Function to handle delete with SweetAlert confirmation
const handleDelete = (id) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        // Get the token from local storage
        const token = localStorage.getItem('token');

        // Make the delete request with the Authorization header
        await axios.delete(`${BASE_SERVER_URL}/subscription/${id}`, {
          headers: { 'token': `${token}` }, // Attach the token in the Authorization header
        });

        Swal.fire(
          'Deleted!',
          'Your subscription has been deleted.',
          'success'
        );

        fetchContacts(); // Refresh the list after deletion
      } catch (error) {
        console.error('Error deleting subscription:', error);
        Swal.fire(
          'Error!',
          'Failed to delete the subscription. Please try again.',
          'error'
        );
      }
    }
  });
};


  return (
    <>
      <Adminpanel onToggleSidebar={handleSidebarToggle} />
      <div className={`contactget-container ${isSidebarOpen ? "open" : "close"}`}>
        <h2 className="contactget-heading">Subscription List</h2>
        <div className="contactget-table-wrapper">
          <table className="contactget-table">
            <thead>
              <tr className="contactget-table-header-row">
                <th className="contactget-table-header">S.No</th>
                <th className="contactget-table-header">Name</th>
                <th className="contactget-table-header">Email</th>
                <th className="contactget-table-header">City</th>
                <th className="contactget-table-header">Actions</th>
              </tr>
            </thead>
            <tbody>
              {contacts.length > 0 ? (
                contacts.map((contact, index) => (
                  <tr key={contact._id} className="contactget-table-row">
                    <td className="contactget-table-data">{index + 1}</td>
                    <td className="contactget-table-data">{contact.name}</td>
                    <td className="contactget-table-data">{contact.email}</td>
                    <td className="contactget-table-data">{contact.city}</td>
                    <td className="contactget-table-data">
                      <button
                        className="contactget-delete-button"
                        onClick={() => handleDelete(contact._id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="contactget-no-data-row">
                  <td colSpan="5" className="contactget-no-data">No Subscription available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Subscription;
