import React, { useState, useEffect } from "react";
import "../../css/Legends.css"; 
import "../../css/Spotlightwall.css";
import axios from "axios";
import Adminpanel from "../Dashboard/Adminpanel";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { BASE_SERVER, BASE_SERVER_URL } from "../../utils/Api";


const Spotlightwall = () => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role"); // Fetch the role from localStorage
  const [glbFiles, setGlbFiles] = useState([]);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  useEffect(() => {
    fetchImagesFromAPI(); // Fetch images when the component mounts
  }, []);

  const handleDelete = (id) => {
    // Admin action: delete functionality
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {

          const token = localStorage.getItem("token");
          // Make the DELETE request to your API
          await axios.delete(`${BASE_SERVER_URL}/images/${id}`, {
            headers: {
            token: token
            },
          });

          // Update the state after successful deletion
          setGlbFiles((prevFiles) =>
            prevFiles.filter((file) => file.id !== id)
          );

          Swal.fire("Deleted!", "Your item has been deleted.", "success");
        } catch (error) {
          console.error(
            "Error deleting item:",
            error.response ? error.response.data : error.message
          );
          const errorMessage = error.response
            ? error.response.data.message || "Failed to delete the item."
            : "Network error. Please check your connection and try again.";
          Swal.fire("Error!", errorMessage, "error");
        }
      }
    });
  };

  const fetchImagesFromAPI = async () => {
    setIsLoading(true);
    setTimeoutError(false);

    const timer = setTimeout(() => {
      setIsLoading(false);
      setTimeoutError(true);
    }, 45000); // 45 seconds timeout

    try {
      const response = await axios.get(`${BASE_SERVER_URL}/images`);
      const arr = response.data.posts.reverse().map((element) => ({
        frontImg: `${BASE_SERVER}/${element.frontImg}`,
        backImg: `${BASE_SERVER}/${element.backImg}`,
        textData: element.textData,
        textLink: element.textLink,
        id: element._id,
      }));

      setGlbFiles(arr);
      setIsLoading(false);
      clearTimeout(timer);
    } catch (error) {
      console.error("Error fetching images from API:", error);
      const errorMessage = error.response
        ? "Failed to load images from the API. Please try again later."
        : "Network error. Please check your connection and try again.";
      setError(errorMessage);
      setIsLoading(false);
      clearTimeout(timer);
    }
  };

  const handleViewCans = () => {
    navigate("/Uploadcan");
  };

  return (
    <>
      {/* Admin's Panel */}
      {role === "admin" && (
        <>
          <Adminpanel onToggleSidebar={handleSidebarToggle} />
          <div
            className={`contactget-container ${
              isSidebarOpen ? "open" : "close"
            }`}
          >
            <h2 className="contactget-heading">All Cans</h2>
            <button className="uploadcan-view-btn" onClick={handleViewCans}>
              Upload Can
            </button>
            <div className="admin-container d-flex flex-wrap">
              {/* Spinner */}
              {isLoading && (
                <div className="spinner-container">
                  <div className="spinner"></div>
                </div>
              )}
  
              {/* Timeout Error Message */}
              {timeoutError && (
                <p style={{ color: "red", width: "100%", textAlign: "center" }}>
                  Data is not available at the moment. Please refresh or try again later.
                </p>
              )}
  
              {/* Conditional Content for Admin */}
              {!isLoading && !timeoutError && (
                <>
                  {error && <p style={{ color: "red" }}>{error}</p>}
  
                  {/* Check if there is no image data */}
                  {glbFiles.length === 0 ? (
                    <p style={{ color: "red", width: "100%", textAlign: "center" }}>
                      No image data available.
                    </p>
                  ) : (
                    // If image data exists, map through the data and display the cans
                    glbFiles.map((data, index) => (
                      <div
                        key={index}
                        className="can-container"
                        style={{ position: "relative" }}
                      >
                        <div className="product">
                          {/* Apply front and back images to the same soda element */}
                          <div
                            className="soda sidi"
                            style={{
                              "--front-url": `url(${data.frontImg})`,
                              "--back-url": `url(${data.backImg})`,
                            }}
                          ></div>
                        </div>
                        <div className="text-data">
                          {data.textData ? (
                            <p>
                              <Link
                                to={data.textLink ? data.textLink : "#"}
                                style={{ textDecoration: "none" }}
                              >
                                {data.textData}
                              </Link>
                            </p>
                          ) : (
                            <p>No Text Data Available</p>
                          )}
                        </div>
                        <button
                          className="contactget-delete-button"
                          style={{ marginLeft: "0px" }}
                          onClick={() => handleDelete(data.id)}
                        >
                          Delete
                        </button>
                      </div>
                    ))
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
  
};

export default Spotlightwall;
