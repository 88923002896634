import React from 'react'
import Header from '../component/Header'
import Banner from '../component/Banner'
import FreeHydration from '../component/Freehydration'
import HydrationSection from '../component/HydrationSection '
import SubscribeForm from '../component/SubscribeForm'
import Footer from '../component/Footer'

const Home = () => {
  return (
    <>
    <Header/>
    <Banner/>
    <FreeHydration/>
    <HydrationSection/>
    <SubscribeForm/> 
    <Footer/>
    </>
  )
}

export default Home