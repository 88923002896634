import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Adminpanel from './Adminpanel';
import '../../css/Dashboard.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import { BASE_SERVER_URL } from '../../utils/Api';

const Dashboard = () => {
  const [dataCounts, setDataCounts] = useState({
    quotes: 0,
    contact: 0,
    distribution: 0,
    subscription: 0,
  });

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchDataCounts = async () => {
      setIsLoading(true); // Set loading state before fetching data
      try {
        // Get the token from local storage
        const token = localStorage.getItem('token');
    
        // Make all API calls simultaneously using Promise.all
        const [quotesResponse, contactResponse, distributionResponse, subscriptionResponse] = await Promise.all([
          axios.get(`${BASE_SERVER_URL}/quotes`, { headers: { 'token': `${token}` } }),
          axios.get(`${BASE_SERVER_URL}/contact`, { headers: { 'token': `${token}` } }),
          axios.get(`${BASE_SERVER_URL}/distribution`, { headers: { 'token': `${token}` } }),
          axios.get(`${BASE_SERVER_URL}/subscription`, { headers: { 'token': `${token}` } }),
        ]);
    
        // Set the data counts based on the response lengths
        setDataCounts({
          quotes: quotesResponse.data.length,
          contact: contactResponse.data.length,
          distribution: distributionResponse.data.length,
          subscription: subscriptionResponse.data.length,
        });
    
        console.log(subscriptionResponse);
      } catch (error) {
        console.error('Error fetching data counts:', error);
      } finally {
        setIsLoading(false); // Reset loading state
      }
    };
    

    fetchDataCounts();
  }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };
const handleQuotes = () =>{
  navigate("/quotes")
}
const handleContactget = () =>{
  navigate("/Contactget")
}
const handlesubscription = () =>{
  navigate("/subscription")
}
const handleDistrubution = () =>{
  navigate("/Distrubution")
}
  return (
    <>
      <Adminpanel onToggleSidebar={handleSidebarToggle} />

      {
        isLoading ? (
          <div className="spinner-container text-center">
          <div className="spinner"></div>
        </div>
        ) : (
          <div className={`dashboard-container ${isSidebarOpen ? "open" : "close"} `}>
          <div className="card quotes-card" onClick={handleQuotes}>
            <h3>Quotes</h3>
            <p>{dataCounts.quotes}</p>
          </div>
          <div className="card contact-card" onClick={handleContactget}>
            <h3>Contact</h3>
            <p>{dataCounts.contact}</p>
          </div>
          <div className="card distribution-card" onClick={handleDistrubution}>
            <h3>Distribution</h3>
            <p>{dataCounts.distribution}</p>
          </div>
          <div className="card subscription-card" onClick={handlesubscription}>
            <h3>Subscription</h3>
            <p>{dataCounts.subscription}</p>
          </div>
        </div> 
        )
      }
      
    </>
  );
};

export default Dashboard;
