import React from 'react';
import 'aos/dist/aos.css'; // Ensure you import AOS styles
import AOS from 'aos'; // Import AOS for animations
import { useEffect } from 'react';
import img from '../images/banner_both2.png'

const FreeHydration = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with animation duration
  }, []);

  return (
    <div className="p-4 rounded shadow-sm bg-light">
      <div className="container p-0">
        <div className="row">
          <div className="col-md-5">
            <img
              src={img}
              alt="Banner"
              data-aos="fade-up"
              className="img-fluid"
            />
          </div>
          <div className="col-md-7 d-flex align-items-center justify-content-center">
            <div className="p-3 bg-light rounded shadow">
              <h4
                style={{
                  color: '#22B1DF',
                  textAlign: 'justify',
                  fontWeight: 300,
                  wordSpacing: '3px',
                  letterSpacing: '1px',
                  lineHeight: '40px',
                }}
                data-aos="fade-up"
              >
                FreeHydration is an{' '}
                <span style={{ color: 'limegreen' }}>advertising platform</span>{' '}
                that redefines the art of promotion by using{' '}
                <span style={{ color: 'limegreen' }}>premium spring water</span>{' '}
                as a unique canvas for businesses to showcase their brand. Best
                of all, we are making hydration accessible to everyone,{' '}
                <span style={{ color: 'limegreen' }}>free of charge</span>. Don't miss out on the opportunity to put your message
                directly into the hands of potential customers.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeHydration;
