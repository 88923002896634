import React, { useState, useEffect } from "react";
import "../css/Legends.css"; // Import custom CSS for styling
import Header from "../component/Header";
import Footer from "../component/Footer";
import "../css/Spotlightwall.css";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { BASE_SERVER, BASE_SERVER_URL } from "../utils/Api";
import { Link } from "react-router-dom";

const Spotlightwall = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [glbFiles, setGlbFiles] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);

  useEffect(() => {
    fetchImagesFromAPI(); // Fetch images when the component mounts
  }, []);

  const fetchImagesFromAPI = async () => {
    setIsLoading(true);
    setTimeoutError(false);

    const timer = setTimeout(() => {
      setIsLoading(false);
      setTimeoutError(true);
    }, 45000); // 45 seconds timeout

    try {
      const response = await axios.get(`${BASE_SERVER_URL}/images`);
      const arr = response.data.posts.reverse().map((element) => ({
        frontImg: `${BASE_SERVER}/${element.frontImg}`,
        backImg: `${BASE_SERVER}/${element.backImg}`,
        textData: element.textData,
        textLink: element.textLink,
        id: element._id,
      }));

      setGlbFiles(arr);
      setIsLoading(false);
      clearTimeout(timer);
    } catch (error) {
      console.error("Error fetching images from API:", error);
      const errorMessage = error.response
        ? "Failed to load images from the API. Please try again later."
        : "Network error. Please check your connection and try again.";
      setError(errorMessage);
      setIsLoading(false);
      clearTimeout(timer);
    }
  };

  return (
    <>
      <Header />
      <div className="container" style={{ marginBottom: "120px" }}>
        <div className="row p-5 text-center">
          <h1 style={{ color: "#22B1DF" }} data-aos="zoom-out-down">
            Our Legends - You too be a part of this journey
          </h1>
          <p
            style={{
              textAlign: "justify",
              fontWeight: 300,
              wordSpacing: "3px",
              letterSpacing: "1px",
              lineHeight: "30px",
            }}
          >
            Welcome to the FreeHydration Spotlight wall, where we recognize the
            contributions of those who have made a meaningful impact on our
            mission of providing free and accessible water for all. Each person
            on this list has made a donation to help us in our efforts to make
            free hydration a reality. Though any condition, whether a small or
            big amount, contributes to making a big difference in our efforts to
            promote the idea that free hydration is a basic human right, not a
            privilege. We are proud to honor these individuals by inducting them
            into our Spotlight wall, where their names will remain for life, as
            a testament to their commitment to a better world.
          </p>
        </div>

        <div className="container d-flex flex-wrap">
          {/* Loading Spinner */}
          {isLoading && (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          )}

          {/* Timeout Error Message */}
          {timeoutError && (
            <p style={{ color: "red", width: "100%", textAlign: "center" }}>
              Data is not available at the moment. Please refresh or try again
              later.
            </p>
          )}

          {/* Conditional Content for Images */}
          {!isLoading && !timeoutError && (
            <>
              {error && <p style={{ color: "red" }}>{error}</p>}

              {/* Check if there is no image data */}
              {glbFiles.length === 0 ? (
                <p style={{ color: "red", width: "100%", textAlign: "center" }}>
                  No image data available.
                </p>
              ) : (
                // If image data exists, map through the data and display the cans
                glbFiles.map((data, index) => (
                  <div
                    key={index}
                    className="can-container"
                    style={{ margin: "20px 10px" }}
                  >
                    <div className="product">
                      {/* Apply front and back images to the same soda element */}
                      <div
                        className="soda sidi"
                        style={{
                          "--front-url": `url(${data.frontImg})`,
                          "--back-url": `url(${data.backImg})`,
                        }}
                      ></div>
                    </div>
                    <div className="text-data">
                      {data.textData ? (
                        <p>
                          <Link
                            to={data.textLink ? data.textLink : "#"}
                            style={{ textDecoration: "none" }}
                          >
                            {data.textData}
                          </Link>
                        </p>
                      ) : (
                        <p>No Text Data Available</p>
                      )}
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Spotlightwall;
