import React from 'react';
import 'aos/dist/aos.css'; // Ensure AOS styles are imported
import '../css/YourComponent.css'; // Import your custom CSS if needed
import img from '../images/red_can.png'
// import { Link } from 'react-router-dom';
const YourComponent2 = () => {
  return (
    <div className="container pb-5">
      <div className="row">
        <div className="col-md-8">
          <h3
            className="text-center"
            style={{ color: '#22B1DF' }}
            data-aos="zoom-in-down"
          >
            Our product is a blank canvas
          </h3>
          <div
            className="mt-3 mb-3 border-bottom"
            style={{ width: '50%', margin: 'auto' }}
          ></div>
          <p
            className="text-justify"
            style={{ color: '#22B1DF' }}
            data-aos="zoom-in-down"
          >
            We are the opposite of traditional advertising because we are not annoying.
            Instead of making the audience cringe or run for cover, we put a smile on their
            face, and from that state of happiness is when they'll be excited to support your
            brand.
          </p>
          <div
            className="mt-3 mb-3 border-bottom"
            style={{ width: '50%', margin: 'auto' }}
          ></div>
        <div className="row cardingg">
  <div className="col-md-6 menin text-center">
    <div className="card p-4 m-2 bg-light shadow-sm" data-aos="zoom-in-down">
      <h4 style={{ color: '#22B1DF' }}>Use QR Codes</h4>
      <div className="mt-3 mb-3 border-bottom" style={{ width: '50%', margin: 'auto' }}></div>
      <p style={{ color: '#22B1DF' }}>
        to connect anything on the internet, such as websites, coupons, and videos
      </p>
    </div>
  </div>
  <div className="col-md-6 menin text-center" data-aos="zoom-in-down">
    <div className="card p-4 m-2 bg-light shadow-sm">
      <h4 style={{ color: '#22B1DF' }}>Sell or Freely Distribute</h4>
      <div className="mt-3 mb-3 border-bottom" style={{ width: '50%', margin: 'auto' }}></div>
      <p style={{ color: '#22B1DF' }}>
        ad supported FreeHydration in aluminum bottles and paper cartons
      </p>
    </div>
  </div>
  <div className="col-md-6 menin text-center mt-4" data-aos="zoom-in-down">
    <div className="card p-4 m-2 bg-light shadow-sm">
      <h4 style={{ color: '#22B1DF' }}>Choose a Distribution Model</h4>
      <div className="mt-3 mb-3 border-bottom" style={{ width: '50%', margin: 'auto' }}></div>
      <p style={{ color: '#22B1DF' }}>
        if you want us to distribute it in Austin or ship it anywhere in the lower 48 states
      </p>
    </div>
  </div>
  <div className="col-md-6 menin text-center mt-4" data-aos="zoom-in-down">
    <div className="card p-4 m-2 bg-light shadow-sm">
      <h4 style={{ color: '#22B1DF' }}>
        Subsidize or Cover the Entire Cost by Sharing the Ad Space
      </h4>
      <div className="mt-3 mb-3 border-bottom" style={{ width: '50%', margin: 'auto' }}></div>
      <br />
      <p style={{ color: '#22B1DF' }}>with non-competing brands</p>
    </div>
  </div>
  <div className="col-md-12">
    <div className="button-container mt-4" data-aos="zoom-in-down">
      <a href="#getQuote" className="btn">
        Advertise with us
      </a>
    </div>
  </div>
</div>

        </div>
        <div className="col-md-4 d-flex justify-content-center" data-aos="zoom-in-down">
          <img
            src={img}
            className="img-fluid"
            style={{ maxHeight: '700px' }}
            alt="Red Can"
          />
        </div>
      </div>
    </div>
  );
};

export default YourComponent2;