import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';

const Termscondition = () => {
  return (
    <>
    <Header/>
    <div className="container-fluid" >
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 text-center" style={{ position: 'relative' }}>
            <div
              style={{
                fontWeight: 300,
                wordSpacing: '3px',
                letterSpacing: '1px',
                lineHeight: '35px',
                marginTop: '80px',
                marginBottom: '80px'
              }}
            >
              <h2 style={{ fontWeight: 600, fontSize: '40px' }} id='Termscondition'>Terms & Conditions</h2>
              <p>Welcome to FreeHydration!</p>
              <p>
                Please read these Terms and Conditions carefully before purchasing our products.
                By placing an order with FreeHydration, you agree to the terms outlined below.
              </p>

              <h3 style={{ color: '#22b1df' }}>Introduction</h3>
              <p>
                FreeHydration specializes in providing bespoke branded water cans, and we strive
                to ensure that every product meets the expectations of our customers. To avoid any
                misunderstandings, we have established the following terms and conditions.
              </p>

              <h3 style={{ color: '#22b1df' }}>Refunds & Returns</h3>
              <ul>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Final Sale:</span> All sales
                  made on FreeHydration are final. Once an order is placed and delivered, there are
                  no refunds, exchanges, or returns accepted under any circumstances. We encourage
                  customers to review their orders carefully before making a purchase.
                </li>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Delivery Confirmation:</span>{' '}
                  By placing an order of our product, you acknowledge that the item meets your
                  expectations in terms of water quality and artwork.
                </li>
              </ul>

              <h3 style={{ color: '#22b1df' }}>​Artwork & Colour Code</h3>
              <ul>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Colour Representation:</span>{' '}
                  We make every effort to accurately display the colours of artworks colour codes
                  provided to us. However, due to differences in monitor settings, lighting
                  conditions, and individual perception, the colours of the actual product may vary
                  slightly from what you see on your screen.
                </li>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Colour Variations:</span> Any
                  minor variations in colour between the digital display and the physical product
                  are not considered defects and will not be eligible for a refund or return after
                  the artwork has been signed off on.
                </li>
              </ul>

              <h3 style={{ color: '#22b1df' }}>Deposits</h3>
              <ul>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Upfront Deposit:</span> A
                  non-refundable deposit is required upfront to secure your order. The deposit
                  amount will be specified during the checkout process. By paying the deposit, you
                  confirm your commitment to the purchase.
                </li>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Balance Payment:</span> The
                  remaining balance must be paid before the product is shipped or delivered. Failure
                  to complete the payment will result in the cancellation of your order, and the
                  deposit will not be refunded.
                </li>
              </ul>

              <h3 style={{ color: '#22b1df' }}>Order Confirmation & Delivery</h3>
              <ul>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Order Review:</span> When
                  confirming an order you will be sent artwork proofs to sign off on. It is your
                  responsibility to review the order details and ensure that all information,
                  including texts and colours, are correct.
                </li>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Delivery Time:</span> We will
                  make every effort to deliver your product within the estimated timeframe (2 to 3
                  weeks). However, delivery times may vary depending on location and unforeseen
                  circumstances, and we cannot guarantee a specific delivery date.
                </li>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Damage Allowance:</span> We
                  strive to ensure that all cans are delivered in perfect condition. However, due to
                  the nature of transit, a margin of up to 3% of the total cans in any shipment that
                  is equal to or greater than 1 pallet (3,600 cans) may be damaged without
                  eligibility for a refund or replacement. This margin is considered acceptable and
                  within the standard allowance for transit-related damages.
                </li>
              </ul>

              <h3 style={{ color: '#22b1df' }}>Customer Responsibilities</h3>
              <ul>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Accurate Information:</span>{' '}
                  It is the customer’s responsibility to provide accurate and complete information
                  when placing an order. FreeHydration will not be responsible for any errors in the
                  delivery of the product due to incorrect information provided by the customer.
                </li>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Acceptance of Terms:</span>{' '}
                  By making a purchase, you confirm that you have read, understood, and agree to
                  these Terms and Conditions.
                </li>
              </ul>

              <h3 style={{ color: '#22b1df' }}>Changes to Terms & Conditions</h3>
              <ul>
                <li>
                  <span style={{ color: 'darkcyan', fontWeight: 600 }}>Amendments:</span>{' '}
                  FreeHydration reserves the right to amend these Terms and Conditions at any time
                  without prior notice. Any changes will be posted on our website and will apply to
                  all future orders.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Termscondition;
