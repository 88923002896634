import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../css/Contact.css'; // Import the CSS file
import Adminpanel from './Adminpanel';
import Swal from 'sweetalert2';
import { BASE_SERVER_URL } from '../../utils/Api';
const Contactget = () => {
  const [contacts, setContacts] = useState([]);

  // Fetch contact data from the API
  useEffect(() => {
   

    fetchContacts();
  }, []);
  const fetchContacts = async () => {
    try {

      // Get the token from local storage
      const token = localStorage.getItem('token');

      const response = await axios.get(`${BASE_SERVER_URL}/contact`, {
        headers: {
          'token': `${token}`, // Attach the token in the Authorization header
        },

      }); // Replace with your actual API URL
      // Reverse the data to show the latest contacts first
      setContacts(response.data.reverse());
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Get the token from local storage
          const token = localStorage.getItem('token');
  
          // Make the delete request with the Authorization header
          const response = await axios.delete(`${BASE_SERVER_URL}/contact/${id}`, {
            headers: { 'token': `${token}` }, // Attach the token in the Authorization header
          });
  
          console.log('Delete Response:', response.data); // Check response from API
          Swal.fire('Deleted!', 'Your contact has been deleted.', 'success'); // Adjusted the message to reflect the action
          fetchContacts(); // Refresh the list after deletion
        } catch (error) {
          console.error('Error deleting contact:', error.response ? error.response.data : error.message);
          Swal.fire(
            'Error!',
            'Failed to delete the contact. Please try again.',
            'error'
          );
        }
      }
    });
  };
  
  return (
    <>
      <Adminpanel onToggleSidebar={handleSidebarToggle} />
      <div className={`contactget-container ${isSidebarOpen ? "open" : "close"}`}>
        <h2 className="contactget-heading">Contact List</h2>
        <div className="contactget-table-wrapper">
        <table className="contactget-table">
          <thead>
            <tr className="contactget-table-header-row">
              <th className="contactget-table-header">S.No</th>
              <th className="contactget-table-header">Name</th>
              <th className="contactget-table-header">Email</th>
              <th className="contactget-table-header">Message</th>
              <th className="contactget-table-header">Phone</th>
              <th className="contactget-table-header">Actions</th>
            </tr>
          </thead>
          <tbody>
            {contacts.length > 0 ? (
              contacts.map((contact, index) => (
                <tr key={index} className="contactget-table-row">
                  <td className="contactget-table-data">{index + 1}</td> {/* Serial number column */}
                  <td className="contactget-table-data">{contact.name}</td>
                  <td className="contactget-table-data">{contact.email}</td>
                  <td className="contactget-table-data">{contact.message}</td>
                  <td className="contactget-table-data">({contact.ccode}){contact.phone}</td>
                  <td className="contactget-table-data">
                      <button
                        className="contactget-delete-button"
                        onClick={() => handleDelete(contact._id)}
                      >
                        Delete
                      </button>
                    </td>
                </tr>
              ))
            ) : (
              <tr className="contactget-no-data-row">
                <td colSpan="6" className="contactget-no-data">No contacts available</td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
      </div>
    </>
  );
};

export default Contactget;
